<template>
  <div v-if="editor">
    <v-toolbar dense flat outlined>
      <template>
        <v-item-group color="primary">
          <v-btn
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-header-1</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-header-2</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-header-3</v-icon>
          </v-btn>
        </v-item-group>
        <v-divider class="mx-2" vertical></v-divider>
        <v-item-group color="primary">
          <v-btn
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
        </v-item-group>
        <v-divider class="mx-2" vertical></v-divider>
        <v-item-group color="primary">
          <v-btn
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'is-active': editor.isActive('underline') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-format-strikethrough</v-icon>
          </v-btn>



          <v-btn
            @click="setLink()"
            :class="{ 'is-active': editor.isActive('link') }"
            elevation="0"
            tile
            plain
          >
            <v-icon>mdi-link-variant-plus</v-icon>
          </v-btn>

        </v-item-group>
        <v-divider class="mx-2" vertical></v-divider>

        <v-item-group color="primary">
          <v-btn
            @click="
              editor.chain().focus().toggleHighlight({ color: 'Yellow' }).run()
            "
            :class="{ 'is-active': editor.isActive('highlight', {color: 'Yellow' }) }"
            color="yellow"
            tile
            plain
          >
            <v-icon color="yellow">mdi-format-color-highlight</v-icon>
          </v-btn>
          <v-btn
            @click="
              editor.chain().focus().toggleHighlight({ color: 'Chartreuse' }).run()
            "
            :class="{ 'is-active': editor.isActive('highlight', {color: 'Chartreuse' }) }"
            color="green"
            tile
            plain
          >
            <v-icon>mdi-format-color-highlight</v-icon>
          </v-btn>
          <v-btn
            @click="
              editor.chain().focus().toggleHighlight({ color: 'SkyBlue' }).run()
            "
            :class="{ 'is-active': editor.isActive('highlight', {color: 'SkyBlue' }) }"
            color="blue"
            tile
            plain
          >
            <v-icon>mdi-format-color-highlight</v-icon>
          </v-btn>
          <v-btn
            @click="
              editor.chain().focus().toggleHighlight({ color: 'Tomato' }).run()
            "
            :class="{ 'is-active': editor.isActive('highlight', {color: 'Tomato' }) }"
            color="red"
            tile
            plain
          >
            <v-icon>mdi-format-color-highlight</v-icon>
          </v-btn>
        </v-item-group>
        <v-divider class="mx-2" vertical></v-divider>
        <v-item-group color="primary">
          <v-btn
            @click="
              editor.chain().focus().unsetAllMarks().run()
            "
            tile
            plain
          >
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </v-item-group>
      </template>
    </v-toolbar>
    <editor-content
      :editor="editor"
      class="v-sheet v-sheet--outlined theme--light"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";

export default {
  name: "wysiwygEditor",
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    renderLinks: {
      type: Boolean,
      default: true,
    },
    renderTables: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    setLink() {
      let previousUrl = this.editor.getAttributes('link').href
      let url = prompt("URL", previousUrl);
      if (url === null) {
        return
      }
      else if (url === '') {
        this.editor.chain().focus().unsetLink().run();
        return
      }

      if (!url.match(/^https?:\/\//) && !url.startsWith("/")) {
        url = 'https://' + url
      }
      this.editor.chain().focus().extendMarkRange("link").setLink({ href: url, target: '_blank' }).run();
    },
  },
  mounted() {
    const ext = [
          StarterKit.configure({
          blockquote: false,
          codeblock: false,
          horizontalrule: false,
          heading: {
            levels: [1, 2, 3],
          },
        }),

        Highlight.configure({ multicolor: true }),
        Underline
    ]
    if(this.renderLinks) ext.push(Link)
    if(this.renderTables) ext.push(
      Table,
      TableRow,
      TableHeader,
      TableCell
    )
    this.editor = new Editor({
      content: this.value,
      extensions: ext,
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.ProseMirror {
  a {
    pointer-events: none !important;
    cursor: default;
  }
  padding: 1rem;
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    p {
      margin: 0;
    }
  }
  li p {
    margin: 0;
  }
  li:last-child p {
    margin-bottom: 16px;
  }
}
.is-active {
  background-color: #d1d1d1 !important;
}
</style>
